import OutlineAccentRoundButton from '../../components/buttons/outline-accent-round-button/OutlineAccentRoundButton';
import { SendForVerify } from '../../requests/AuthRequests';
import { getUser, getUserPhoto, updateUserPhoto, updateUser } from '../../requests/UserRequests';
import './account-page.scss';
import { useEffect, useState } from 'react';

// interface UserData {
//   photo: string,
//   _id: string,
//   phone: number,
//   email: string,
//   name: string,
//   passwordHash: string,
// }

// interface UserPhoto {
//   photo: string,
// }

export default function AccountPage() {
  const [userData, setUserData] = useState/*<UserData | null>*/(null);
  const [userPhoto, setUserPhoto] = useState/*<UserPhoto | null>*/(null);
  const [errorMessage,setErrorMessage] = useState(String);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getUser();
        setUserData(data);
        const photoUrl = await getUserPhoto();
        setUserPhoto(photoUrl);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleUpdatePhoto = (event) => {
    const photo = event.target.files[0];
    const formData = new FormData();
    formData.append('photo', photo);
    updateUserPhoto(formData);
  }

  const handleUpdate = (event) =>{
    event.preventDefault();
    let phoneRegex = /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/;
    let nameRegex = /[^a-zA-Z]+/;
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const formData = new FormData(event.currentTarget);
    const name = event.currentTarget.name.value;
    const email = event.currentTarget.email.value;
    const phone = event.currentTarget.phone.value;

    if(phone.length > 0 && !phoneRegex.test(phone)){
      setErrorMessage('Please provide valid phone number!');
      return;
    }

    if(name.length <= 0){
      setErrorMessage('Name cant be empty!');
      return;
    }

    if(nameRegex.test(name)){
      setErrorMessage('Name can contain only letters!');
      return;
    }

    if(email.length <= 0){
      setErrorMessage('Email cant be empty!');
      return;
    }

    if(!emailRegex.test(email)){
      setErrorMessage('Email is invalid!');
      return;
    }
    
    setErrorMessage('');
    updateUser(formData);
  }

  const handleExit = (event) =>{
    event.preventDefault();
    localStorage.removeItem('jwt');
    window.location.href = "/";
  }

  return (
    <div className='full-height'>
      <div className="full-width">
        <div className="account-container">
          {userData ? (
            <>
              <div className="account-main">
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img src={userPhoto}/>
                </label>
                <input id="file-input" type="file" name="userPhoto" onChange={handleUpdatePhoto}/>
              </div>
              </div>
              <form className='account-inputs' onSubmit={handleUpdate}>
                <input className='px24 w500' placeholder='Name' name="name" defaultValue={userData.name}/>
                <input className='px18 w500' placeholder='Email' name="email" defaultValue={userData.email}/>
                {userData.emailVerified ? null : <button type="button" onClick={ () => SendForVerify(userData.email)}>Verify</button>}
                <input className='px18 w500' defaultValue={userData.phone} name="phone" placeholder='Phone' />
                {errorMessage ? <h4 className='red w500'>{errorMessage}</h4> : null}
                <div className='buttons-container'>
                  <OutlineAccentRoundButton text="Save"/>
                  <button className='delete-button'  onClick = {handleExit} type="button">Exit</button> 
                </div>
              </form>
            </>
          ) : 'Login/Signup to see your credentials'}
        </div>
      </div>
    </div>
  );
}
