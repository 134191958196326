import ContactItem from './components/contact-item/ContactItem';
import './contacts-page.scss';

export default function ContactsPage(){
    return(
        <div className='full-height'>
            <div className="full-width">
                <div className="columns-6">
                    <div className="page-header">
                        <h1 className="px65 w500">Contacts</h1>
                        <h2 className="px28 w400">The main information about us</h2>
                    </div>
                    <div className="contacts-container">
                        <ContactItem name = "+371259039222"/>
                        <ContactItem name = "onecupoftea93@gmail.com"/>
                        <ContactItem name = "Cupertino, CA 95014, U.S.A."/>
                    </div>
                </div>
            </div>
        </div>
    );
}
