import { deleteItem } from '../../../../requests/CartRequests';
import './cart-bag-item.scss';
export default function CartBagItem(props:{id:string,name:string,photo:string,hex:string,price:number,tags:number}){
    const handleDelete = () =>{
        deleteItem(props.id);
    }
    return(
        <div className="cart-bag-item" style={{ backgroundColor: props.hex}}>
            <div className='info'>
                <div className='tags-info'>
                   <img src="./assets/svg/tags.svg"/>
                    <h5>{props.tags}</h5> 
                </div>
                <button onClick={handleDelete}>
                    <img src="./assets/svg/krestic.svg"/>
                </button> 
            </div>
            <img src = {props.photo}/>
            <div className='cart-bag-info'>
                <h2>{props.name}</h2>
                <h3 className='accent'>{props.price} $</h3>
            </div>
        </div>
    );
}