import { useState } from 'react';
import ProceedLongButton from '../../../components/buttons/proceed-long-button/ProceedLongButton';
import {Signup} from '../../../requests/AuthRequests';
import './../auth-pages.scss';

export default function SignupPage(){
    let [errorMessage,setErrorMessage] = useState("");
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const signup = await Signup(formData);
        if(signup !== true){
            setErrorMessage(signup)
        }
    };
    return(
        <div className='full-height'>
            <div className="auth-page-full-width">
                <form className='auth-page-content' onSubmit = {handleSubmit}>
                    <h1 className='px40 w500'>Create an account</h1>
                    <div className='buttons-container'>
                        <input name="name" placeholder='Name'/>
                       <input name="email" placeholder='Email'/>
                       <input name="password" type="password" placeholder='Password'/> 
                       <input name="repeatPassword" type="password" placeholder='Repeat password'/>
                       {errorMessage ? (<h4 className='red w500'>{errorMessage}</h4>) : null}
                    </div>
                    <ProceedLongButton/>
                </form>
            </div>
        </div>
    );
}