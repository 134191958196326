import ProceedLongButton from '../../../../components/buttons/proceed-long-button/ProceedLongButton';
import './bag-item.scss';

export default function BagItem(props: { name: string, id : string, photo: string, hex: string, price:number}){
    return(
        <div className='bag-item-container' style={{ backgroundColor: props.hex}}>
        <div className='bag-item-content'>
          <div className='price-holder'>
            <h1 className='rotated white w500'>{props.price} $</h1>
          </div>
        <img src={props.photo}/>
        <div className='info-holder'>
          <h1 className='rotated white w500 px28'>{props.name}</h1>
        </div>     
      </div>
      <a href={"/bag?id="+props.id}><ProceedLongButton/></a>
      </div>
    );
}